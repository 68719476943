<template>
  <div>
    <!-- <TopCarousel /> -->
    <img class="w-full banner-top" src="@/assets/images/service/banner.png" />
    <ContentArea>
      <div class="service-container">
        <div class="service-item">
          <img :src="fileBaseUrl + serviceObj.serviceOrderImage" />
          <div class="img-desc">{{ serviceObj.serviceOrderTitle }}</div>
          <div class="tool-tip">{{ serviceObj.serviceOrderContent }}</div>
        </div>
        <div class="service-item">
          <img :src="fileBaseUrl + serviceObj.serviceLogisticsImage" />
          <div class="img-desc">{{ serviceObj.serviceLogisticsTitle }}</div>
          <div class="tool-tip">{{ serviceObj.serviceLogisticsContent }}</div>
        </div>
        <div class="service-item">
          <img :src="fileBaseUrl + serviceObj.serviceWarehouseImage" />
          <div class="img-desc">{{ serviceObj.serviceWarehouseTitle }}</div>
          <div class="tool-tip">{{ serviceObj.serviceWarehouseContent }}</div>
        </div>
        <div class="service-item">
          <img :src="fileBaseUrl + serviceObj.serviceProfessionImage" />
          <div class="img-desc">{{ serviceObj.serviceProfessionTitle }}</div>
          <div class="tool-tip">{{ serviceObj.serviceProfessionContent }}</div>
        </div>
      </div>
    </ContentArea>
  </div>
</template>
<script>

export default {
  name: 'Service',
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      serviceObj: {}
    }
  },
  created () {
    this.$service.serviceInfo().then(res => {
      if (!res.data) {
        this.$message.warn(this.$t('common.noData'))
        return
      }
      this.serviceObj = res.data
    })
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.service-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .service-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .img-desc {
      transform: translateY(-50%);
      background: #376E25;
      width: 150px;
      text-align: center;
      padding: 6px 0;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      border-radius: 4px;
    }

    .tool-tip {
      position: absolute;
      height: 75%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      color: #FFFFFF;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (min-width:1024px) {
  .service-item {
    width: calc((60vw - 10px) / 2);
  }
}

@media screen and (max-width:1000px) {
  .service-item {
    width: 90vw;
  }
}
</style>
